import {
    Middleware,
    MiddlewareData,
    Placement,
    arrow,
    autoUpdate,
    computePosition,
    flip,
    offset,
    shift
} from '@floating-ui/dom';
import InitializationError from '@naturehouse/nh-essentials/lib/exceptions/InitializationError';

export enum PlacementOption {
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    LEFT = 'left',
    TOP_START = 'top-start',
    TOP_END = 'top-end',
    RIGHT_START = 'right-start',
    RIGHT_END = 'right-end',
    BOTTOM_START = 'bottom-start',
    BOTTOM_END = 'bottom-end',
    LEFT_START = 'left-start',
    LEFT_END = 'left-end'
}

export interface iFloatingUIElement extends HTMLElement {
    placement: Placement;
    target: HTMLElement | null;
    element: HTMLElement;
    arrowElement: HTMLElement | null;
    show: () => void;
    close: () => void;
}

type MiddlewareOptions = {
    offset: {
        mainAxis: number;
        alignmentAxis: number;
    };
    shift: {
        padding: number;
    };
    arrow: {
        element: HTMLElement | null;
        padding: number;
    };
};

export default class FloatingUIElementManager<T extends iFloatingUIElement> {
    readonly #componentInstance: T;

    readonly #defaultMiddlewareOptions: MiddlewareOptions;

    public constructor(componentInstance: T) {
        this.#componentInstance = componentInstance;
        this.#defaultMiddlewareOptions = {
            offset: {
                mainAxis: 0,
                alignmentAxis: 0
            },
            shift: {
                padding: 0
            },
            arrow: {
                element: this.#componentInstance.arrowElement,
                padding: 8
            }
        };
    }

    public async updatePosition(options: Partial<MiddlewareOptions> = {}): Promise<void> {
        if (this.#componentInstance.target === null) {
            return;
        }

        const middleware: Middleware[] = this.#getMiddleWare({
            ...this.#defaultMiddlewareOptions,
            ...options
        });

        const {
            x,
            y,
            placement: p,
            middlewareData
        } = await computePosition(this.#componentInstance.target, this.#componentInstance.element, {
            placement: this.#componentInstance.placement,
            middleware: middleware
        });

        Object.assign(this.#componentInstance.element.style, {
            position: 'absolute',
            left: `${x}px`,
            top: `${y}px`
        });

        this.#componentInstance.placement = p;

        if (this.#componentInstance.arrowElement !== null) {
            this.#calculateArrowPosition(middlewareData, p, this.#componentInstance);
        }
    }

    public cleanupFloatingUi(): void {
        if (this.#componentInstance.target === null) {
            return;
        }

        const cleanup = autoUpdate(
            this.#componentInstance.target,
            this.#componentInstance.element,
            async (): Promise<void> => {
                await this.updatePosition();
                this.#componentInstance.element.style.cssText = '';
            },
            {
                elementResize: typeof ResizeObserver === 'function'
            }
        );

        cleanup();
    }

    #getMiddleWare(options: MiddlewareOptions): Middleware[] {
        const middleware: Middleware[] = [
            offset({
                mainAxis: options.offset.mainAxis,
                alignmentAxis: options.offset.alignmentAxis
            }),
            shift({
                padding: options.shift.padding
            }),
            flip()
        ];

        if (options.arrow?.element) {
            middleware.push(
                arrow({
                    element: options.arrow.element,
                    padding: options.arrow.padding
                })
            );
        }

        return middleware;
    }

    #calculateArrowPosition(
        middlewareData: MiddlewareData,
        placement: Placement,
        instance: iFloatingUIElement
    ): void {
        const arrowElement: Element | null =
            instance.element.querySelector('[slot="arrow"]') ?? instance.arrowElement;
        const placing: string[] = placement.split('-');

        if (!middlewareData.arrow || !arrowElement) {
            throw new InitializationError();
        }

        const staticSideY: string = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right'
        }[placing[0]] as string;

        const { x: arrowX, y: arrowY } = middlewareData.arrow;

        Object.assign((arrowElement as HTMLDivElement).style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            [staticSideY]: '-6px'
        });
    }
}
